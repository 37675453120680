.buttonCourse{
    display: flex;
    justify-content: center;
    align-items: center;

    width: max-content;
    min-width: 280px;
    /* width: 290px; */
    height: 40px;
    border-radius: 30px;

    gap: 5px;
    margin: 0 5px;
    padding: 11px 16px;
    
    font-family: 'Montserrat', sans-serif, Arial, Helvetica;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    transition: .2s;
    cursor: pointer;
    text-decoration: none;
}
.empty{
    background-color: transparent;
    border: 2px solid #0097A9;
    color: #0097A9;
}
.fill{
    background-color: white;
    color: #0097A5;
}
.buttonCourse:hover{
    background-color: #0097A9;
    color: #fff;
}

@media only screen and (max-width: 1200px) {
    .buttonCourse{
        font-size: 10px;
        padding: 8px 14px;

        width: max-content;
        box-sizing: border-box;
        height: 32px;
    }
}