/* RESPONSIVE */

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .home-link-wrapper{ display: block; }
    .home-link{ font-size: 11px; line-height: 13px; }
    .home-about-wrapper{ flex-direction: column; padding: 16px; }
    .home-header{ flex-direction: column; gap: 25px; padding: 40px 0 0 0; }
    .home-button-wrapper{ flex-direction: column; gap: 10px; }
    .home-button-wrapper a{ width: 100%; }
    .home-title-big{ font-size: 46px; }
    .home-title-normal{ font-size: 38px; line-height: 42px; }
    .home-slider-controller{ display: none; }
    /* .home-slider-wrapper{ height: 148px; } */
    .home-slider-wrapper{ height: auto; }
    .home-about-text{
        font-size: 21px;
        line-height: 20px;
        margin-top: 35px;
    }
    .home-about-image{
        width: 100%;
        height: 222px;
        background-image: url(../../assets/images/about-imageX05.png);
        background-repeat: no-repeat;
        border-radius: 9px 28px 28px 28px;
    }
    .home-about-image-wrapper{
        display: flex;
        width: 100%;
    }
    .home-text-big{
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.05em;
    }
    .home-about-wrapper{ gap: 20px; }
    .home-products-wrapper{ flex-direction: column; gap: 30px; }
    .home-schedule-content{ padding-bottom: 0; }
    .home-schedule-products{
        flex-direction: column;
        align-items: center;
        padding: 24px 0;
        gap: 10px;
    }
    .home-schedule-products-wrapper{ transform: scale(0.85); }
    .home-schedule-list-wrapper{
        display: flex;
        flex-direction: column;
        gap: 16px;
        /* padding: 0 12px; */
    }
    .home-schedule-list-text{
        font-size: 16px;
        line-height: 18px;
        letter-spacing: 0.04em;
    }
    /* .home-slider-image{ min-height: 180px; } */
    .home-slider-image{ height: 50vw; }
    .prev, .next{ transform: scale(0.5); }
    .home-products-example{ border-radius: 20px; transform: scale(0.8); }
    .home-products-example-image{
        border: none;
        width: 90%;
        height: 500px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        border-radius: 20px;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .home-header{ justify-content: space-between; gap: 0; }
    .home-logo{ width: 122px; }
    .home-button-wrapper{
        flex-direction: column;
        align-items: flex-end;
        gap: 20px;
    }
    .home-link-wrapper{ display: block; }
    .home-link{ font-size: 11px; line-height: 15px; }
    .home-about-wrapper{
        max-width: 100%;
        justify-content: center;
        flex-direction: column;
    }
    .home-slider-image{ max-height: 400px; }
    .home-products-wrapper{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 60px;
    }
    .home-products-example{ padding: 8px; border-radius: 64px; transform: scale(0.8); }
    .home-products-example-image{
        border: none;
        box-shadow: none;
        width: 402px;
        height: 388px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        border-radius: 60px;
    }
    .home-about-image{
        width: 520px;
        height: 333px;
        background: url(../../assets/images/about-imageX075.png);
        background-position: center;
    }
    /* .home-schedule-list-wrapper{ padding-left: 30px; } */
    .home-schedule-list-text{ font-size: 20px; }
    .home-product-image{ width: 400px; }
    .prev, .next{ transform: scale(0.6); }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .home-schedule-products{ gap: 30px; }
    .home-header{ justify-content: space-between; gap: 60px; }
    .home-button-wrapper{ flex-direction: row; }
    .home-product-image{ width: 450px; }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .home-button-wrapper{ padding: 0 30px; }
    .home-header{ padding: 40px 0px 0px 0px; gap: 20px; }
    .home-link-wrapper{ margin: 0 6px; display: block; }
    .home-link{ font-size: 12px; }
    .prev, .next{ transform: scale(0.8); }
    .home-title-big{ font-size: 50px; }
    .home-about-wrapper{ flex-direction: row; }
    .home-about-info{ max-width: 345px; }
    .home-about-text{ font-size: 20px; line-height: 19px; }
    .home-product-1{ background: url(../../assets/images/home-product-1.jpg); }
    .home-product-2{ background: url(../../assets/images/home-product-2.jpg); }
    .home-products-example-image{
        width: 402px;
        height: 400px;
        /* height: 258px; */
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        border: 5px solid #0097A9;
        box-shadow: -18px 14px 0px #0097A9;
    }
    .home-title-normal{ font-size: 36px; line-height: 36px; }
    .home-text-big{ font-size: 21px; line-height: 22px; }
    .line-small-yellow{ width: 162px; height: 4px; }
    .home-products-wrapper{ flex-direction: row; }
    .line-small{ width: 172px; height: 7px; }
    .home-schedule-list-text{ font-size: 20px; }
    .home-products-example{ padding: 0px; border-radius: 80px; transform: scale(0.8); }
    .home-schedule-products{ gap: 80px; }
    .home-product-image{ width: 450px; }
    .home-slider-image{ max-height: 526px; }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .home-logo{ width: 232px; }
    .home-link{ font-size: 14px; line-height: 17px; }
    .home-title-big{ font-size: 68px; }
    .home-about-info{ max-width: 430px; }
    .home-about-text{ font-size: 25.5px; line-height: 24px; }
    .home-about-image{
        width: 737px;
        height: 444px;
        background: url(../../assets/images/about-image.png);
        background-position: center;
        border-radius: 17px 56px 56px 56px;
    }
    .home-product-1{ background: url(../../assets/images/home-product-1.jpg); }
    .home-product-2{ background: url(../../assets/images/home-product-2.jpg); }
    .home-products-example-image{
        width: 520.03px;
        height: 525px;
        /* height: 338.1px; */
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        border-radius: 80px;
    }
    /* .home-slider-image{ max-height: 726px; } */
    .home-slider-image{ max-height: 824px; }
    .home-products-wrapper{ gap: 80px; flex-direction: row; }
    .home-title-normal{ font-size: 44px; line-height: 54px; }
    .home-text-big{ font-size: 25px; line-height: 24px; }
    .home-schedule-list-text{ font-size: 24px; line-height: 36px; }
    .line-small{ width: 184px; height: 9px; }
    .home-product-image{ width: 500px; }
    .prev, .next{ transform: scale(0.9); }
}