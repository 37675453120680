/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .support-form-input{ font-size: 14px; line-height: 15px; padding: 9px 18px; }
    .support-form-wrapper{ flex-direction: column; padding: 50px 10px; gap: 18px; }
    .support-form-submit{ height: 32px; padding: 5px 16px; font-size: 10px; }
    .support-form-title{ font-size: 36px; line-height: 40px; }
    .support-form-subtitle{ font-size: 22px; line-height: 30px; }
    .support-form-title-wrapper{ gap: 0px; }

    .support-form-input{ font-size: 14px; line-height: 15px; padding: 9px 18px; }
    .support-form-wrapper{ flex-direction: column; padding: 50px 10px; }
    .support-form-submit{ height: 32px; padding: 5px 16px; font-size: 10px; }

    .support-form-input-wrapper{ flex-direction: column; gap: 0px !important; }
    .support-form-input-wrapper input{ width: 100% !important; }
    .support-form-form{ padding: 0 12px; }
    .support-image-wrapper{ height: 400px; background-position: top; }
}
  
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .support-form-subtitle{ font-size: 48px; line-height: 34px; }
    .support-form-title{ font-size: 88px; line-height: 70px; }
    .support-form-input{ padding: 8px 14px; }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
} 

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
} 

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .support-form-subtitle{ font-size: 60px; line-height: 38px; }
    .support-form-title{ font-size: 119px; line-height: 106px; }
    .support-form-input{ padding: 11px 18px; }

}