.courses-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
    position: relative;
    overflow: hidden;
}
.courses-body-wrapper{
    display: flex;
    width: 90%;
    padding: 80px 10px;
}
.courses-wrapper{
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 40px;
    width: 50%;
    padding-right: 30px;
    z-index: 2;
    background-color: #EDEEE9;
}
.course{
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.course-title{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 900;
    font-size: 54px;
    line-height: 95.4%;
    text-transform: uppercase;
    color: #333333;
    margin: 0;
    max-width: 461px;
}
.course-text{
    font-family: 'Hubballi';
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 36px;
    color: #858585;
    margin: 0;
    padding: 10px;
    max-width: 518px;
}
.course-text-wrapper{
    display: flex;
    gap: 20px;
}
.teachers-wrapper{
    padding: 0 46px;
}
.teachers-title{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 900;
    font-size: 64px;
    line-height: 95.4%;
    text-transform: uppercase;
    color: #0097A9;
    margin: 0;
}
.events-content{
    display: flex;
    justify-content: center;
    width: 90%;
    padding: 15px 0 25px 0;
}
.events-calendar-wrapper{
    width: 95%;
    height: 555px;
    background: white;
    border: 8px solid #0097A9;
    border-radius: 54px;
    box-shadow: 17px 23px 0px #0097A9;
    overflow: hidden;
}
.events-calendar{   
    width: 100%;
    height: 100%;
    background-color: white;
    padding: 15px;
}

.events-calendar .today-button{
    background-color: blue;
}

