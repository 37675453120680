
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .courses-wrapper{ width: 100%; }
    .courses-body-wrapper{ flex-direction: column; }
    .course-title{ font-size: 44px; }
    .course-text{ font-size: 24px; line-height: 30px; }
    .v-line-wrapper .v-line-fat{ width: 3px; }
    .teachers-wrapper{ padding: 20px 0 0 0; }
    .teachers-title{ font-size: 46px; }
    .teachers-slider{ justify-content: center; padding: 32px 0 !important; }
    .teachers-slider-wrapper{ min-width: auto !important; }
    .teacher-wrapper{ transform: scale(0.9); }
    .teachers-slider-controller-wrapper{ transform: scale(0.7); }

    .events-calendar-wrapper{ height: 455px; }
}
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .courses-body-wrapper{ flex-direction: column; gap: 40px; }
    .v-line{ display: none; }
    .background-slider{ display: none; }
    .courses-wrapper{ width: 100%; }
    .course-title{ max-width: max-content; }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
} 

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
} 

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .courses-body-wrapper{ flex-direction: row; gap: 0px; }
    .v-line{ display: block; }
    .background-slider{ display: block; }
    .courses-wrapper{ width: 50%; }
    .course-title{ max-width: 461px; }
}