.teachers-slider-wrapper{
    min-width: 435px;
}
.teachers-slider-wrapper .background-slider{
    /* position: absolute;
    width: 100vw;
    height: 65%;
    max-height: 680px;
    background: rgba(172, 172, 169, 0.2);
    filter: blur(16px);
    transform: rotate(350deg);
    top: 469px;
    z-index: 1; */

    position: absolute;
    width: 100vw;
    height: 100%;
    max-height: 715px;
    background: rgba(172, 172, 169, 0.2);
    filter: blur(16px);
    transform: rotate(349deg);
    top: 205px;
    z-index: 1;
}

.teachers-slider-controller-wrapper{
    display: flex;
    justify-content: space-around;
    width: 435px;
}
.teachers-slider-controller{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    width: 140px;
    height: 50px;
    border: 2px solid #0097A9;
    border-radius: 9px;
    z-index: 2;

    -webkit-user-select: none;
    -webkit-touch-callout: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #0097A9;
    cursor: pointer;
}
.teachers-slider{
    display: flex;
    position: relative;
    z-index: 2;
    padding: 45px 0;
    gap: 35px;
    flex-wrap: wrap;
    max-width: 610px;
}
.teacher-wrapper{
    position: relative;
    text-decoration: none;
}
.teacher-image{
    width: 200px;
    height: 200px;
    border-radius: 20px;
    background-color: black;
}
.teacher-name-wrapper{
    display: flex;
    align-items: center;
    gap: 15px;
    margin: 15px 0;
    max-width: 200px;
}
.teacher-name{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 900;
    font-size: 22px;
    line-height: 95.4%;
    text-transform: uppercase;
    color: #0097A9;
    margin: 0px;
}
.teacher-button{
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 35px;
    background: #C4C4C4;
    border-radius: 9px;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 800;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    color: #FFFFFF;
    text-transform: uppercase;
    transition: .4s;
}
.teacher-button:hover{
    font-size: 11px;
}
