.products-details-screen{
    overflow: hidden;
}
.products-details-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
}
.products-details-wrapper{
    display: flex;
    justify-content: center;
    min-height: max-content;
    gap: 32px;
    padding: 55px 0;
}
.products-details-title{
    /* max-width: 469px; */
    max-width: 524px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 900;
    font-size: 62px;
    line-height: 70px;
    text-transform: uppercase;
    color: #0097A9;
    margin: 0;
}
.products-details-description{
    font-family: 'Hubballi';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 22px;
    color: #4B4B4B;
    max-width: 400px;
    margin: 0 24px;
    text-align: justify;
}
.products-details-sample{
    width: 155px;
    height: 105px;
    margin: 0 28px;
}
.products-details-info{
    display: flex;
    padding: 20px 0;
}
.products-details-image{
    /*width: 675px; */
    /*height: 545px;*/
    height: max-content;
    border-radius: 8.42963px;
}
.products-details-image img{ max-width: 740px; }
.horizontal-line{
    width: 3px;
    /* max-height: 368px; */
    background-color: #0097A9;
}


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .products-details-image img{ max-width: 100%; }
    .products-details-description{ max-width: 100%; }
    .products-slider-wrapper{ flex-direction: column-reverse; gap: 0px !important; }
    .products-details-wrapper{ max-width: 100%; min-height: 220px; padding-bottom: 40px; }
    .products-details-title{ font-size: 44px; line-height: 52px; }
    .products-details-description{ font-size: 20px; line-height: 19px; }
    .products-slider-wrapper{ width: 100% !important; }
    .products-slider-title{ font-size: 46px !important; line-height: 48px !important; }
    .products-slider-text{ font-size: 20px !important; line-height: 19px !important; }
    .products-slider{ transform: scale(0.8); }
    .background-slider{ display: none; }
    .products-slider-controller-wrapper{ width: 100%; transform: scale(0.7); position: relative; }
    .products-slider-wrapper .flex-column{ gap: 0 !important; max-height: 450px; }
    .products-details-wrapper{ flex-wrap: wrap; }
    .products-slider-back, .products-slider-next{ width: 84% !important; height: auto !important; }
    .products-slider-back{ left: -36px !important; }
    .products-slider-next{ right: -36px !important; }
  }
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
    /* .products-details-image{ transform: scale(0.8); } */
    .products-details-description{ max-width: 600px; }
    .products-slider-wrapper{ flex-direction: column-reverse; }
    .products-details-wrapper{ flex-wrap: wrap; }

  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
  } 
  
  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    .products-details-description{ max-width: 400px; }
    .products-details-image img{ max-width: 500px; }
    .products-details-wrapper{ gap: 2rem; }
    .products-slider-center{
        width: 303px !important;
        height: 376px !important;
    }
    .products-slider-back, .products-slider-next{
        width: 242px !important;
        height: 316px !important;
    }
    .products-slider-back{ left: -42px !important; }
    .products-slider-next{ right: -42px !important; }
    .products-slider-wrapper{ flex-direction: row; }
    .products-slider-title{
        font-size: 46px !important;
        line-height: 58px !important;
    }
    .products-slider-wrapper{ gap: 60px !important; }
    .products-slider-text{ font-size: 22px !important; }
    .products-details-wrapper{ flex-wrap: nowrap; }
  } 
  
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    .products-details-image{ transform: scale(1); }
    .products-details-description{ max-width: 400px; }
    .products-slider-wrapper{ flex-direction: row; }
    .products-slider-center{
        width: 404px !important;
        height: 505px !important;
    }
    .products-slider-back, .products-slider-next{
        width: 323px !important;
        height: 422px !important;
    }
    .products-slider-back{ left: -56px !important; }
    .products-slider-next{ right: -56px !important; }
    .products-slider-title{
        font-size: 59px;
        line-height: 72px;
    }
    .products-slider-wrapper{ gap: 92px !important; }
    .products-slider-text{ font-size: 24px !important; }
  }
