.products-header.fixed{
    position: fixed;
    background: #EDEEE9;
    z-index: 999;
    width: 100%;
}

.top-header{
    padding: 0 40px;
}
.top-header-wrapper{
    display: flex;
    justify-content: space-between;
    padding: 0 40px;
}
.top-header-link-wrapper, .top-header-social-wrapper{
    display: flex;
    gap: 15px;
    padding: 15px;
    align-items: center;
}
.top-header-social{
    display: flex;
    width: 18px;
    transition: .4s;
}
.top-header-social:hover{
    transform: scale(1.2);
}
.top-header-link-wrapper .link{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #333333;
    text-decoration: none;
}
.top-header-link-wrapper .link:hover{
    cursor: pointer;
    text-decoration: underline;
}
.main-header{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.main-header-wrapper{
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 0;
    /* padding: 60px 0 32px 0; */
}
.main-header-buttons{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 20px;
}
.button-find-new-selling{
    display: flex;
    align-items: center;
    width: 230px;
}
.button-find-new-selling input{
    width: 100%;
    padding: 10px;
    border: none;
    outline: none;
    background-color: transparent;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #0097A9;
}

/* PLACEHOLDER STYLES */

::-webkit-input-placeholder { /* Edge */
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #0097A9;
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #0097A9;
}
::placeholder {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #0097A9;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .top-header-wrapper{
        justify-content: center;
        padding: 0;
    }
    .top-header{
        padding: 0;
    }
    .top-header-link-wrapper .link{ font-size: 10px; }
    .top-header-social{ width: 16px; }

    .thin-line{ width: 90%; margin: auto }
    .main-header-wrapper{ flex-direction: column; }
    .main-header-buttons-wrapper{ flex-direction: column-reverse; gap: 10px; }
    .main-header-buttons{ padding-top: 12px; }
    .main-header-buttons, .main-header-buttons-wrapper{ justify-content: center; align-items: start; width: 100%; }
    .button-find-new-selling{ width: auto; }
    .button-find-new-selling input{ font-size: 12px; }
    .button-find-new-selling img{ width: 18px; }



    /* PLACEHOLDER STYLES */

    ::-webkit-input-placeholder { /* Edge */
        font-size: 12px;
        line-height: 17px;
    }
    :-ms-input-placeholder { /* Internet Explorer 10-11 */
        font-size: 12px;
        line-height: 17px;
    }
    ::placeholder {
        font-size: 12px;
        line-height: 17px;
    }
}