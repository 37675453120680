/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .distributor-form-title{ font-size: 40px; line-height: 44px; }
    .distributor-form-subtitle{ font-size: 20px; line-height: 19px; }
    .distributor-form-input, .distributor-form-submit{ width: 100%; }
    .distributor-form-input{ font-size: 14px; line-height: 15px; padding: 9px 18px; }
    .distributor-form-wrapper{ flex-direction: column; padding: 50px 10px; }
    .distributor-form-submit{ height: 32px; padding: 5px 16px; font-size: 10px; }
    .fat-line-power-wrapper{ transform: scale(0.6); }
    .distributor-benefits-dot{ display: none; }
    .distributor-benefits-title{ font-size: 40px; }
    .distributor-benefits-text{ width: 100vw; font-size: 18px; padding: 0 20px; }
    .distributor-benefits-image-wrapper{ height: 400px; background-position: top; }
    .distributor-benefits-text-wrapper div{ flex-direction: column; }


    /* PLACEHOLDER STYLES */

    ::-webkit-input-placeholder { /* Edge */
        font-size: 12px;
        line-height: 17px;
    }
    :-ms-input-placeholder { /* Internet Explorer 10-11 */
        font-size: 12px;
        line-height: 17px;
    }
    ::placeholder {
        font-size: 12px;
        line-height: 17px;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .distributor-form-title{ font-size: 74px; line-height: 88px; }
    .distributor-form-subtitle{ font-size: 24px; line-height: 23px; }
    .distributor-form-input, .distributor-form-submit{ width: 100%; }
    .distributor-form-wrapper{ flex-direction: column; }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .distributor-form-wrapper{
        justify-content: center;
        align-items: center;
    }
    .distributor-form-form{ width: 100%; }

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .distributor-form-wrapper{ flex-direction: row; }
    .distributor-form-input, .distributor-form-submit{ width: 380px; }

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .distributor-form-title{ font-size: 88px; line-height: 92px; }
    .distributor-form-subtitle{ font-size: 32px; line-height: 31px; }
    .distributor-form-input, .distributor-form-submit{ width: 509px; }
    .distributor-form-form{ width: auto; }
    .distributor-form-wrapper{ justify-content: space-between; }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1600px) {
    .distributor-form-wrapper{ justify-content: center; gap: 200px; }
}