.footer-1, .footer-2{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.footer-1{
    padding: 40px 0 34px 0;
    gap: 40px;
}
.footer-2{
    background-color: #ffffff;
}
.footer-copyright{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 30px;
}
.footer-logo{
    width: 169px;
}
.footer-social-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    margin-top: 25px;
}
.footer-social, .footer-social img{
    width: 18px;
    transition: .4s;
}
.footer-social:hover{
    transform: scale(1.2);
}
.footer-links-wrapper{
    display: flex;
    gap: 50px;
}
.footer-links-wrapper div{
    display: flex;
    flex-direction: column;
}
.footer-link{
    display: flex;
    align-items: center;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 30px;
    color: #333333;
    text-decoration: none;
}
.footer-link.link:hover{
    cursor: pointer;
    text-decoration: underline;
}
.footer-link.bold{
    width: 190px;
    height: 36px;
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
}
.footer-link.email{
    font-size: 12px;
}

@media only screen and (max-width: 1024px) {
    .distributor-wrapper{ display: none; }
    .footer-link{ font-size: 12px; }
    .footer-1{ gap: 20px; }
    .footer-links-wrapper{ gap: 20px; }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .footer-1{ flex-direction: column; gap: 14px; }
    .footer-links-wrapper{
        flex-wrap: wrap;
        align-items: center;
        gap: 12px;
        justify-content: center;
    }
    .footer-social-wrapper{ margin-top: 18px; }
    .footer-social{ display: flex; align-items: center; }
    .footer-link{ font-size: 12px; line-height: 26px; }
    .footer-links-wrapper div{ align-items: center; }
    .footer-copyright{ font-size: 10px; line-height: 25px; }
}