.buttonDistributor{
    display: flex;
    justify-content: center;
    align-items: center;

    /* max-width: 230px; */
    width: -webkit-fill-available;
    height: 40px;
    color: #5E420F;
    border-radius: 20px;
    padding: 11px 16px;
    margin: 0 5px;

    font-family: 'Montserrat', sans-serif, Arial, Helvetica;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-decoration: none;
    
    transition: .2s;
    cursor: pointer;
}
.dark{ background-color: #FEB835; }
.light{ background-color: #F6CF00; }
.buttonDistributor:hover{
    background-color: #F6CF00;
}

@media only screen and (max-width: 1200px) {
    .buttonDistributor{
        font-size: 10px;
        padding: 10px 15px;

        height: 32px;
    }
}