.distributor-form-content{
    display: flex;
    justify-content: center;
}
.alertSuccess{
    position: absolute;
    z-index: 99999;
    width: 100%;
    margin-top: 5px;
    left: 50%;
    transform: translate(-50%, 0px) scale(1);
}
.distributor-form-wrapper{
    display: flex;
    justify-content: space-between;
    width: 90%;
    padding: 60px 0;
    gap: 20px;
}
.distributor-form-label{
    max-width: 596px;
}
.distributor-form-title{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 900;
    font-size: 88px;
    line-height: 92px;
    color: #333333;
}
.distributor-form-subtitle{
    font-family: 'Hubballi';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 31px;
    letter-spacing: 0.075em;
    text-transform: uppercase;
    color: #0097A9;
}
.distributor-form-input{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 11px 18px;
    gap: 18px;
    background-color: transparent;
    border: 3px solid #0097A9;
    border-radius: 10px;
    box-sizing: border-box;
    margin-top: 16px;
    width: 509px;
    font-family: 'Hubballi';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 17px;
    text-transform: capitalize;
    color: #0097A9;
}
.distributor-form-input.email, .distributor-form-input.title, .distributor-form-input.message{
    text-transform: none;
}
.distributor-form-input input{
    height: 39px;
}
.distributor-form-submit{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    padding: 11px 16px;
    gap: 10px;
    width: 508px;
    height: 41px;
    margin-top: 18px;
    background: #333333;
    border-radius: 30px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.275em;
    text-transform: uppercase;
    color: #FFFFFF;
}
.distributor-form-submit:hover{
    cursor: pointer;
}
.distributor-benefits-wrapper{
    position: relative;
    padding: 25px 0;
}
.distributor-benefits-title{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 900;
    font-size: 51px;
    line-height: 104.4%;
    text-transform: uppercase;
    color: #333333;
}
.distributor-benefits-text-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
}
.distributor-benefits-text-wrapper div{
    display: flex;
    align-items: center;
    gap: 18px;
}
.distributor-benefits-text{
    font-family: 'Hubballi';
    font-style: normal;
    font-weight: 400;
    font-size: 21px;
    line-height: 104.4%;
    color: #0097A9;
    width: 358px;
    margin: 0;
}
.distributor-benefits-dot{
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background: #333333;
}
.distributor-benefits-image-wrapper{
    width: 90%;
    height: 500px;
    margin: 45px auto;
    background: url('../../assets/images/distributor-image2.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    border-radius: 33px;
}
