.products-list{
    display: flex;
    justify-content: center;
}
.products-list-wrapper{
    display: flex;
    flex-wrap: wrap;
    width: 90%;
    padding: 80px 0;
    margin-bottom: 120px;
    gap: 85px;
    justify-content: space-around;
}
.product-wrapper{
    position: relative;
    text-decoration: none;
}

.adjust-plus-icon .product-icon{
    bottom: 124px;
}

@keyframes rotateIcon {
    0% {
        width: 72px;
        height: 72px;
        border-radius: 36px;
        transform: rotate(0deg);
    }
    10% {
        width: 80px;
        height: 80px;
        border-radius: 40px;
    }
    100% {
        width: 80px;
        height: 80px;
        border-radius: 40px;
        transform: rotate(90deg);
    }
}
.product-wrapper:hover .product-icon{
    animation-name: rotateIcon;
    animation-duration: .5s;
    width: 80px;
    height: 80px;
    border-radius: 40px;
}
.product-image, .product-image img{
    width: 315px;
    border-radius: 25px 25px 25px 9px;
    height: auto;
    margin: auto;
    /* height: 246px; */
}
.product-icon{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 72px;
    height: 72px;
    position: absolute;
    bottom: 50px;
    right: -30px;
    border-radius: 36px;
    background: #0097A9;
    transition: .2s;
}
.product-name{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
    color: #333333;
    margin: 20px 0;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .product-wrapper{ transform: scale(0.8); }
    .products-list-wrapper{ gap: 0px; }
}