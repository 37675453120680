.background-slider{
    position: absolute;
    width: 200vw;
    height: 124%;
    background: rgba(172, 172, 169, 0.2);
    filter: blur(16px);
    transform: rotate(350deg);
    top: -132px;
    z-index: 1;
}
.new-line{
    margin-top: 260px;
}
.products-slider-wrapper{
    display: flex;
    position: relative;
    gap: 78px;
    padding-bottom: 60px;
}
.products-slider-wrapper .flex-column{ gap: 42px; }
.products-slider{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    height: max-content;
    border-radius: 33px;
    z-index: 2;
}
.products-slider-center{
    position: relative;
    z-index: 10;
    width: 404px;
    height: auto;
    /* height: 505px; */
    border-radius: 33px;
    background-color: white;
    box-shadow: 0px 0px 155px -15px rgba(0, 0, 0, 0.36);
}
.products-slider-center img, .products-slider-back img, .products-slider-next img{
    width: 100%;
    border-radius: 32px;
    max-height: 100%;
    object-fit: contain;
}
.products-slider-back{
    position: absolute;
    left: -46px;
    width: 322px;
    height: 422px;
    border-radius: 33px;
}
.products-slider-next{
    position: absolute;
    right: -46px;
    width: 322px;
    height: 422px;
    border-radius: 33px;
}
.products-slider-controller-wrapper{
    display: flex;
    justify-content: space-between;
    width: calc(100% + 20px);
}
.products-slider-controller{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    width: 125px;
    height: 46px;
    border: 2px solid #0097A9;
    border-radius: 9px;
    z-index: 2;

    -webkit-user-select: none;
    -webkit-touch-callout: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #0097A9;
    cursor: pointer;
}
.products-slider-title{
    max-width: 320px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 59px;
    line-height: 72px;
    display: flex;
    align-items: flex-end;
    color: #333333;
    z-index: 2;
}
.products-slider-text{
    font-family: 'Hubballi';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 23px;
    color: #0097A9;
    max-width: 440px;
    z-index: 2;
}