/* UTILITY */

.bg-black{ background-color: #333333 !important; }
.line-small{
  width: 184px;
  height: 9px;
  background-color: #0097A9;
  border-radius: 5px;
  margin: auto;
}
.line-small-yellow{
  width: 172px;
  height: 5px;
  background-color: #FEB835;
  border-radius: 3px;
  margin: auto;
}

/* ELEMENTS */
.home-header{
  width: 80%;
  margin: auto;
  justify-content: space-between;
  display: flex;
  justify-content: center;
  gap: 60px;
  align-items: center;
  padding: 60px 40px;
  margin-bottom: 2rem;
  /* background-color: red; */
}

.home-header.fixed{
  position: fixed;
  z-index: 99999;
  background-color: #EDEEE9;
  width: 100%;
}

.home-link-wrapper{
  margin: 0px 13px;
}
.home-link-wrapper:hover{ cursor: pointer; }
.home-link-wrapper:hover .home-link-underline{
  width: 100%;
}
.home-link{
  font-family: 'Montserrat', 'Roboto', sans-serif, Arial, Helvetica;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-decoration: none;
  color: #0097A9;
}
.home-link-underline{
  width: 46px;
  height: 2px;
  background-color: #0097A9;
  margin-top: 4px;
  transition: .4s;
}
.home-slider-wrapper{
  position: relative;
  max-width: 1480px;
  width: 100%;
  margin: auto;
}
.home-products-slider-wrapper{
  position: relative;
  max-width: 1200px;
  width: 100%;
  margin: auto;
}
.home-slider{
    width: 100%;
    text-align: center;
}
.home-slider-image{
    width: 100%;
    height: 100vh;
    max-height: 726px;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: top; 
    /* background-position: center; */


    /* position: absolute;
    left: 0;
    top: 185px; */
}
.home-slider-controller{
    position: absolute;
    width: 100px;
    right: 80px;
}
.prev, .next{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    border-radius: 24px;
    position: absolute;
    font-size: 10px;
    color: white;
    background-color: #2E2E2E;
    margin: 8px;
    cursor: pointer;
}
.prev:hover, .next:hover{
    color: #f0f0f0;
    background-color: #111111;
}
.next{ right: 0; }

.home-about{
  padding: 30px 0;
}
.home-about-wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 40px;
  margin-bottom: 28px;
}
.home-about-info{
  max-width: 430px;
}
.home-title-big{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 900;
  font-size: 68px;
  line-height: 96.9%;
  text-transform: uppercase;
  color: #0097A9;
}
.home-about-text{
  font-family: 'Hubballi';
  font-style: normal;
  font-weight: 400;
  font-size: 25.5px;
  line-height: 24px;
  text-align: justify;
  color: #333333;
}
.home-about-image{
  width: 737px;
  height: 444px;
  background: url(../../assets/images/about-image.png);
  background-position: center;
  border-radius: 17px 56px 56px 56px;
}

.home-products{
  display: flex;
  justify-content: center;
}
.home-products-wrapper{
  display: flex;
  justify-content: center;
  gap: 80px;
  width: 100%;
  padding: 20px 40px;
}
.home-products-example{
  background-color: #0097A9;
  border-radius: 80px;
}
.home-products-example-image{
  width: 535px;
  height: 344px;

  background-repeat: no-repeat;
  background-position: center;
  border: 5px solid #8f4631;
  box-shadow: -18px 14px 0px #0097A9;
  border-radius: 80px;
}
.home-product-1{
  background: url(../../assets/images/home-product-1.jpg);
}
.home-product-2{
  background: url(../../assets/images/home-product-2.jpg);
}

.home-schedule-content{
  padding: 40px 0;
}
.home-schedule-wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
}
.home-title-normal{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  font-size: 44px;
  line-height: 54px;

  text-align: center;
  text-transform: uppercase;
  color: #0097A9;
}
.home-text-big{
  font-family: 'Hubballi';
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 24px;

  text-align: center;
  letter-spacing: 0.16em;
  text-transform: uppercase;
  color: #0097A9;
}
.home-schedule-products{
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 50px 0;
  gap: 80px;
}
.home-schedule-wrapper .prev, .home-schedule-wrapper .next{
  background-color: rgba(255, 255, 255, 0.37);
  border: 2px solid white;
}
.home-schedule-products-wrapper{
  position: relative;
  text-decoration: none;
}
.home-schedule-product-index{
  display: flex;
  justify-content: center;
  align-items: center;

  width: 42px;
  height: 42px;
  background-color: #0097A9;
  border-radius: 21px;
  color: #FFFFFF;
  
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
}
.home-schedule-products-wrapper .home-schedule-product-index{
  position: absolute;
  bottom: 38px;
  left: -21px;
}
.home-schedule-list-item .home-schedule-product-index{
  transform: scale(0.7);
}
.home-schedule-product-name{
  font-family: 'Hubballi';
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
  color: #0097A9;
  margin-top: 28px;
}
.home-schedule-product-image{
  width: 248.43px;
  height: 212px;
  border-radius: 10px;
}
.home-schedule-product-image.index-1{
  background: url(../../assets/images/schedule-product-image-1.png), #FFFFFF;
}
.home-schedule-product-image.index-2{
  background: url(../../assets/images/schedule-product-image-2.png), #FFFFFF;
}
.home-schedule-product-image.index-3{
  background: url(../../assets/images/schedule-product-image-3.png), #FFFFFF;
  /* font-family: 'Montserrat';
  font-style: normal;
  font-weight: 900;
  font-size: 29.1429px;
  line-height: 36px;
  text-align: center;
  text-transform: uppercase;
  background-color: white;
  color: #0097A9;
  display: flex;
  justify-content: center;
  align-items: center; */
}
.home-schedule-product-image.index-4{
  background: url(../../assets/images/schedule-product-image-4.png), #FFFFFF;
}
.home-schedule-product-image.index-5{
  background: url(../../assets/images/schedule-product-image-5.png), #FFFFFF;
}
.home-schedule-product-image.index-6{
  background: url(../../assets/images/schedule-product-image-6.png), #FFFFFF;
}
.home-schedule-product-image.index-7{
  background: url(../../assets/images/schedule-product-image-7.png), #FFFFFF;
}
.home-schedule-product-image.index-8{
  background: url(../../assets/images/schedule-product-image-8.png), #FFFFFF;
}
.home-schedule-product-image.index-9{
  background: url(../../assets/images/schedule-product-image-9.png), #FFFFFF;
}
.home-schedule-list-item{
  display: flex;
  align-items: center;
}
.home-schedule-list-text{
  font-family: 'Hubballi';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #0097A9;
}
