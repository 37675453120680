*{
  box-sizing: border-box;
}
body{
  margin: 0;
  padding: 0;
}
.flex{ display: flex; }
.relative{ position: relative; }
.v-center{ align-items: center; }
.h-center{ justify-content: center; }
.flex-column{ flex-direction: column; }
.flex-column.h-center{ align-items: center; }
.flex-column.v-center{ justify-content: center; }

.j-space-around{ justify-content: space-around; }
.no-margin{ margin: 0 !important; }
.text-end{ text-align: end; }

/* .home-screen{ background-color: #E5E5E5; } */

.home-screen,
.products-screen        ,
.products-details-screen,
.distributor-screen     ,
.support-screen         ,
.courses-screen         {
  background-color: #EDEEE9;
}

.thin-line{
  width: 100%;
  height: 1px;
  background: #333333;
}
.fat-line{
  width: 90%;
  height: 5px;
  margin: auto;
  background: #333333;
}
.fat-line-power-wrapper{
  position: absolute;
  top: -49px;
  padding: 20px;
  background-color: #EDEEE9;
  box-sizing: border-box;
}
@keyframes scrollDown {
  0%   {margin-bottom: 7px;}
  100% {margin-bottom: 22px;}
}
.fat-line-power{
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 34px;
  height: 58px;
  border: 2px solid #333333;
  border-radius: 18px;
}
.fat-line-power-button{
  width: 20px;
  height: 24px;
  background: #333333;
  border-radius: 18px;
  margin-bottom: 7px;
  animation-name: scrollDown;
  animation-duration: 1.4s;
  animation-timing-function: ease-out;
  animation-iteration-count: infinite;
}
.v-line{
  width: 5px;
  /* height: 100%; */
  background-color: #0097A9;
}
.v-line-wrapper{
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.v-line-wrapper .v-line-fat{
  width: 4px;
  flex-grow: 1;
  border-radius: 2px;
  background-color: #333333;
}
.v-line-fat.main{
  flex-grow: 20;
}
