.support-form-content{
    display: flex;
    justify-content: center;
}
.support-form-wrapper{
    display: flex;
    flex-direction: column;
    padding: 100px 0;
    gap: 30px;
}
.support-image-wrapper{
    width: 90%;
    height: 500px;
    margin: 100px auto 45px auto;
    background: url('../../assets/images/distributor-image2.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    border-radius: 33px;
}
.support-form-title-wrapper{
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.support-form-subtitle{
    font-family: 'Hubballi';
    font-style: normal;
    font-weight: 400;
    font-size: 60px;
    line-height: 38px;
    text-align: center;
    letter-spacing: 0.365em;
    color: #333333;
    margin: 0;
}
.support-form-title{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 900;
    font-size: 119px;
    line-height: 106px;
    text-align: center;
    text-transform: uppercase;
    color: #0097A9;
    margin: 0;
}
.support-form-input{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 11px 18px;
    gap: 18px;
    background-color: transparent;
    border: 3px solid #0097A9;
    border-radius: 10px;
    box-sizing: border-box;
    margin-top: 16px;
    width: 100%;
    font-family: 'Hubballi';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 17px;
    text-transform: capitalize;
    color: #0097A9;
}
.support-form-input.email, .support-form-input.title, .support-form-input.message{
    text-transform: none;
}
.support-form-submit{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    padding: 11px 16px;
    gap: 10px;
    width: 100%;
    height: 41px;
    margin-top: 18px;
    background: #333333;
    border-radius: 30px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.275em;
    text-transform: uppercase;
    color: #FFFFFF;
}